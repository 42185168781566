import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { articles } from '../../../../constent/routes';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddArticlesService {

  constructor(private http: HttpClient
  ) {
  }

  storeArticle(data: any): Observable<any> {
    return this.http.post(articles.create, data)
  }

}
