import { Component, Input } from '@angular/core';
import { ShowFamilyTreeService } from '../../../modules/home/components/show-tree-family/service/show-family-tree.service';

@Component({
  selector: 'app-tree-numbers',
  templateUrl: './tree-numbers.component.html',
  styleUrl: './tree-numbers.component.css'
})
export class TreeNumbersComponent {
  maleCount = 0;
  femaleCount = 0;
  lifeCount = 0;
  deadCount = 0;
  total = 0;
  data = [];

  constructor(private treeService: ShowFamilyTreeService,
  ) {

  }

  ngOnInit() {
    this.treeService.getTree().subscribe((res) => {
      this.data = res.data
      this.maleCount = this.data.filter((x: any) => x.is_male == true).length
      this.femaleCount = this.data.filter((x: any) => x.is_male == false).length
      this.lifeCount = this.data.filter((x: any) => x.is_alive == true).length
      this.deadCount = this.data.filter((x: any) => x.is_alive == false).length
      this.total = this.data.length
    })
  }


}
