<div class=" w-full flex-wrap flex justify-center items-center gap-3">
  <div class="flex justify-center items-center gap-1">
    <div class="w-4 h-4 rounded-full bg-[#6dd16d]"></div>
    <span>
      المجموع : {{total}}
    </span>
  </div>
  <div class="flex justify-center items-center gap-1">
    <div class="w-4 h-4 rounded-full bg-[#6DC5D1]"></div>
    <span>
      الذكور : {{maleCount}}
    </span>
  </div>

  <div class="flex justify-center items-center gap-1">
    <div class="w-4 h-4 rounded-full bg-[#F15C5C]"></div>
    <span>
      الاناث : {{femaleCount}}
    </span>
  </div>
  <div class="flex justify-center items-center gap-1">
    <div class="w-4 h-4 rounded-full bg-[#f1ee5c]"></div>

    <span>
      الاحياء : {{lifeCount}}
    </span>
  </div>
  <div class="flex justify-center items-center gap-1">
    <div class="w-4 h-4 rounded-full bg-[#24241b]"></div>

    <span>
      الاموات : {{deadCount}}
    </span>
  </div>
</div>