import { Component } from '@angular/core';

@Component({
  selector: 'app-info-icon-flash',
  templateUrl: './info-icon-flash.component.html',
  styleUrl: './info-icon-flash.component.css'
})
export class InfoIconFlashComponent {

}
