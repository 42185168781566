import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tree } from '../../../../../../constent/routes';

@Injectable({
  providedIn: 'root'
})
export class ShowFamilyTreeService {

  constructor(
    private http: HttpClient
  ) { }

  getTree(): Observable<any> {
    return this.http.get(tree.index);
  }

  addTree(data: any): Observable<any> {
    return this.http.post(tree.create, data);
  }

  editNode(id: any, data: any): Observable<any> {
    return this.http.put(tree.update + id, data);
  }

  deleteNode(id: any): Observable<any> {
    return this.http.delete(tree.delete + id);
  }


}
