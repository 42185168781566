<ng-content></ng-content>
<div class="w-full flex justify-between gap-4 ">

  <div class=" w-1/2  shadow-md rounded-xl p-2 bg-[var(--text-light)] ">
    <form [formGroup]="editNewsForm" (ngSubmit)="submit()">

      <!-- عنوان الخبر  -->
      <div class="mb-4">
        <label for="title" class="text-md font-semibold">العنوان</label>
        <input pInputText type="text" id="title" name="title" placeholder="ادخل العنوان " class="w-full mt-2 shadow-md"
          formControlName="title" />

        <!-- <small class="text-red-500"
          *ngIf="!editNewsForm.get('title')?.valid && editNewsForm.get('title')?.touched ">الاسم
          مطلوب</small> -->
      </div>

      <!-- صورة الخبر  -->


      <!-- صورة الخبر  -->
      <div class="mb-2">
        <label for="cover_image" class="text-md font-semibold">صورة الغلاف</label>
        <p-fileUpload name="demo[]" customUpload="true" [multiple]="false" chooseLabel="اختر صورة" cancelLabel="الغاء"
          accept="image/*" [showUploadButton]="false" [showCancelButton]="false" chooseIcon="pi pi-image ml-2"
          styleClass="mt-2 shadow-md" (onSelect)="onSelect($event)" (onClear)="onClear() "
          (onRemove)="onRemove($event)">

          <ng-template pTemplate="content">
            <div class="flex gap-2">
              <p>الغلاف الحالي</p>
              <img [src]="editNewsForm.get('cover_image')?.value" alt="" width="50px " height="50px">
            </div>
          </ng-template>
        </p-fileUpload>
      </div>

      <div style="direction: ltr !important">
        <p-editor formControlName="content" placeholder="هنا تكتب الخبر..." styleClass="shadow-md "
          [style]="{height: '300px'}">

        </p-editor>
      </div>

      <div class="flex justify-start gap-2 mt-2">
        <p-button type="submit" label="حفظ" severity="success"></p-button>
        <p-button (onClick)="preview()" type="button" label="معاينة قبل الحفظ" severity="info"></p-button>
      </div>

    </form>
  </div>


  <div class=" w-1/2 h-[100vh] shadow-md rounded-xl p-2 bg-[var(--text-light)] overflow-y-auto overflow-x-hidden ">

    <h1 class="text-2xl mt-4 text-center">المعاينة</h1>
    <hr>

    <img *ngIf="previewText?.image_url" class="w-full h-[300px]" [src]="previewText?.image_url" alt="">
    <h1 *ngIf="previewText?.title" class="text-2xl mt-4 text-center"> العنوان :
      {{previewText?.title}}
    </h1>

    <div #tag_container class="img-cont" [innerHTML]="previewText?.text">

    </div>
  </div>

</div>