import { environment } from "../environments/environment";

export const BASE_URL = environment.baseUrl

// auth api
export const auth = {
  login: BASE_URL + "auth/login",
  register: BASE_URL + "auth/register",
  profile: BASE_URL + "auth/profile",
  logout: BASE_URL + "auth/logout"
}
export const tree = {
  create: BASE_URL + "family-members/",
  get: BASE_URL + "trees",
  update: BASE_URL + "family-members/",
  delete: BASE_URL + "family-members/",
  index: BASE_URL + "family-members/"

}
export const users = {
  create: BASE_URL + "users/",
  update: BASE_URL + "users/",
  delete: BASE_URL + "users/",
  show: BASE_URL + "users/",
  update_profile: BASE_URL + "users/update-profile/",
  familyMembers: BASE_URL + "family-members/",
}

export const news = {
  create: BASE_URL + "articles/",
  update: BASE_URL + "articles/",
  delete: BASE_URL + "articles/",
  index: BASE_URL + "articles/",
  changeState: BASE_URL + "articles/"
}
export const articles = {
  create: BASE_URL + "articles/",
  update: BASE_URL + "articles/",
  delete: BASE_URL + "articles/",
  index: BASE_URL + "articles/",
  changeState: BASE_URL + "articles/"
}
