import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../../../auth/service/auth.service';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.css'
})
export class UserMenuComponent {
  constructor(
    private auth: AuthService,
    private toast: ToastService,
    private router: Router,
  ) {

  }

  userMenu: MenuItem[] | undefined;
  checked: boolean = false;
  user = JSON.parse(localStorage.getItem('user') || '{}');
  role = JSON.parse(localStorage.getItem('role') || '{}');
  avatar: any

  toggleDarkTheme(): void {
    let theme = localStorage.getItem('theme');
    if (theme === 'light-theme') {
      localStorage.setItem('theme', 'dark-theme');
    } else {
      localStorage.setItem('theme', 'light-theme');
    }
    document.body.classList.toggle('dark-theme');
  }


  ngOnInit() {

    this.auth.avatarImg.subscribe((res) => {
      this.avatar = res
    })

    // check if role is user or admin
    if (this.role.name === 'user') {
      this.userMenu = [
        {
          label: 'الملف الشخصي',
          icon: 'pi pi-user ',
          route: '/user-profile/' + this.user.id
        },
        {
          label: 'تسجيل الخروج',
          icon: 'pi pi-sign-out  text-red-500',
          command: () => {
            this.logout();
          }
        }
      ]
    }
    else {
      this.userMenu = [
        {
          label: 'الملف الشخصي',
          icon: 'pi pi-user ',
          route: '/user-profile/' + this.user.id
        },
        {
          label: 'لوحة التحكم',
          icon: 'pi pi-cog ',
          route: '/dashboard',
        },
        {
          label: 'تسجيل الخروج',
          icon: 'pi pi-sign-out  text-red-500',
          command: () => {
            this.logout();
          }
        }

      ];
    }
  }

  logout() {
    this.auth.logout();
  }
}
