<div class=" sticky top-0 left-0 right-0  w-full shadow-md navbar " dir="ltr" style="z-index: 999;">
  <p-menubar [model]="items" [baseZIndex]="999">


    <!--  logo -->
    <ng-template pTemplate="start">
      <div class="flex items-center gap-2 text-xl mr-4 ">

        <!-- <i class="pi pi-sitemap
          mr-2"></i> -->
        <h3 routerLink=""
          class="font-bold ml-10 text-[var(--text-light)] cursor-pointer flex justify-center items-center ">
          <img src="../../../../assets/img/icons8-family-tree-64.png" class="w-6 h-6 ml-2 bg-white rounded-full" />

          قبيلة بازيد

        </h3>
      </div>
    </ng-template>


    <!-- nav list  -->
    <ng-template pTemplate="item" let-item let-root="root">
      <a [routerLink]="item.route" routerLinkActive="active font-bold rounded-sm" pRipple
        class="flex items-center p-menuitem-link text-[var(--text-light)]">
        <i *ngIf="item.items" [ngClass]="['pi', root ? 'pi-angle-down ml-2' : 'pi-angle-right']"></i>

        <span [class]="item.icon" routerLinkActive="text-[var(--icon-color)]"></span>
        <span *ngIf="item.label !== 'حديث اليوم'" class="mr-2 ">{{ item.label }}</span>
        <span (click)="showDialog()" *ngIf="item.label == 'حديث اليوم'" class="mr-2 ">{{ item.label }}</span>


      </a>
    </ng-template>

    <!--  user menu  -->
    <app-user-menu class="absolute flex   top-[30px] left-12 "></app-user-menu>
  </p-menubar>

</div>


<p-dialog header="حديث اليوم" [modal]="true" [(visible)]="visible" [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [maximizable]="true">
  <app-loader *ngIf="hadith == null"></app-loader>
  <h4 class="text-center leading-10" *ngIf=" hadith" style="font-family: uthman !important;">
    {{hadith?.contents?.arab}}
  </h4>
</p-dialog>
