import { auth } from './../../../../constent/routes';
import { Component, HostListener } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../../../auth/service/auth.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent {
  items: MenuItem[] | undefined;
  user = this.auth.getUser()
  width = window.innerWidth;


  constructor(private auth: AuthService, private api: HttpClient) {
    this.onResize(window.innerWidth); // Initial call to handle the current width
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const screenWidth = event?.target?.innerWidth;
    this.handleScreenWidthChange(screenWidth);
  }

  handleScreenWidthChange(width: number) {
    // Your logic based on the width
    if (this.width < 1318) {
      console.log(this.width);

      this.items = [
        {
          label: 'شجرة العائلة',
          icon: 'pi pi-sitemap',
          route: '/home/show-family-tree'
        },
        {
          label: 'اخر الاخبار',
          icon: 'pi pi-book',
          route: '/home/news'
        },
        {
          label: 'انجازات الافراد',
          icon: 'pi pi-star-fill',
          route: '/home/achievements'


        },
        {
          label: 'الاسر المنتجة - الاعمال الحرة',
          icon: 'pi pi-warehouse',
          route: '/home/jobs'

        },

        {
          label: 'اخرى',
          icon: 'pi pi-star-fill',
          items: [
            {
              label: 'حديثي التخرج',
              icon: 'pi pi-graduation-cap',
              route: '/home/fresh-graduates'

            },
            {
              label: 'الارشيف',
              icon: 'pi pi-video',
              items: [
                {
                  label: 'الاجتماعات السابقة',
                  icon: 'pi pi-video',
                  route: '/home/archive-meetings'
                },
                {
                  label: 'معرض الصور',
                  icon: 'pi pi-images',
                  route: '/home/gallery'
                },
              ]
            },
            {
              label: 'صندوق العائلة',
              icon: 'pi pi-star-fill',
              route: '/home/treasury'
            },
            {
              label: 'الصحة',
              icon: 'pi pi-heart-fill',
              route: '/home/health'

            },
            {
              label: 'شباب المستقبل',
              icon: '',
              items: [
                {
                  label: 'منشورات',
                  icon: 'pi pi-video',
                  route: '/home/kids-posts'

                },
                {
                  label: 'روابط التعليم',
                  icon: 'pi pi-graduation-cap',
                  route: '/home/kids-learning'

                },
              ]
            },
            {
              label: 'لمحات قانونية',
              icon: 'pi pi-warehouse',
              route: '/home/legal'

            },
            {
              label: 'حديث اليوم',
            }
          ]
        },
      ];
    }
    else {
      this.items = [
        {
          label: 'شجرة العائلة',
          icon: 'pi pi-sitemap',
          route: '/home/show-family-tree'
        },
        {
          label: 'اخر الاخبار',
          icon: 'pi pi-book',
          route: '/home/news'
        },
        {
          label: 'انجازات الافراد',
          icon: 'pi pi-star-fill',
          route: '/home/achievements'


        },
        {
          label: 'الاسر المنتجة - الاعمال الحرة',
          icon: 'pi pi-warehouse',
          route: '/home/jobs'

        },
        {
          label: 'حديثي التخرج',
          icon: 'pi pi-graduation-cap',
          route: '/home/fresh-graduates'


        },
        {
          label: 'الارشيف',
          icon: 'pi pi-video',
          items: [
            {
              label: 'الاجتماعات السابقة',
              icon: 'pi pi-video',
              route: '/home/archive-meetings'

            },
            {
              label: 'معرض الصور',
              icon: 'pi pi-images',
              route: '/home/gallery'

            },]

        },

        {
          label: 'اخرى',
          icon: 'pi pi-star-fill',
          items: [
            {
              label: 'صندوق العائلة',
              icon: 'pi pi-star-fill',
              route: '/home/treasury'


            },

            {
              label: 'الصحة',
              icon: 'pi pi-heart-fill',
              route: '/home/health'

            },
            {
              label: 'روابط التعليم',
              icon: 'pi pi-graduation-cap',
              route: '/home/kids-learning'

            },
            {
              label: 'لمحات قانونية',
              icon: 'pi pi-warehouse',
              route: '/home/legal'

            },
            {
              label: 'حديث اليوم',
            }
          ]
        },
      ];
    }
  }

  ngOnInit() {
    if (this.width < 1318) {
      console.log(this.width);

      this.items = [
        {
          label: 'شجرة العائلة',
          icon: 'pi pi-sitemap',
          route: '/home/show-family-tree'
        },
        {
          label: 'اخر الاخبار',
          icon: 'pi pi-book',
          route: '/home/news'
        },
        {
          label: 'انجازات الافراد',
          icon: 'pi pi-star-fill',
          route: '/home/achievements'


        },
        {
          label: 'الاسر المنتجة - الاعمال الحرة',
          icon: 'pi pi-warehouse',
          route: '/home/jobs'

        },

        {
          label: 'اخرى',
          icon: 'pi pi-star-fill',
          items: [
            {
              label: 'حديثي التخرج',
              icon: 'pi pi-graduation-cap',
              route: '/home/fresh-graduates'

            },
            {
              label: 'الارشيف',
              icon: 'pi pi-video',
              items: [
                {
                  label: 'الاجتماعات السابقة',
                  icon: 'pi pi-video',
                  route: '/home/archive-meetings'
                },
                {
                  label: 'معرض الصور',
                  icon: 'pi pi-images',
                  route: '/home/gallery'
                },
              ]
            },
            {
              label: 'صندوق العائلة',
              icon: 'pi pi-star-fill',
              route: '/home/treasury'
            },
            {
              label: 'الصحة',
              icon: 'pi pi-heart-fill',
              route: '/home/health'

            },
            {
              label: 'روابط التعليم',
              icon: 'pi pi-graduation-cap',
              route: '/home/kids-learning'

            },
            {
              label: 'لمحات قانونية',
              icon: 'pi pi-warehouse',
              route: '/home/legal'

            },
            {
              label: 'حديث اليوم',
            }
          ]
        },
      ];
    }
    else {
      this.items = [
        {
          label: 'شجرة العائلة',
          icon: 'pi pi-sitemap',
          route: '/home/show-family-tree'
        },
        {
          label: 'اخر الاخبار',
          icon: 'pi pi-book',
          route: '/home/news'
        },
        {
          label: 'انجازات الافراد',
          icon: 'pi pi-star-fill',
          route: '/home/achievements'


        },
        {
          label: 'الاسر المنتجة - الاعمال الحرة',
          icon: 'pi pi-warehouse',
          route: '/home/jobs'

        },
        {
          label: 'حديثي التخرج',
          icon: 'pi pi-graduation-cap',
          route: '/home/fresh-graduates'


        },
        {
          label: 'الارشيف',
          icon: 'pi pi-video',
          items: [
            {
              label: 'الاجتماعات السابقة',
              icon: 'pi pi-video',
              route: '/home/archive-meetings'

            },
            {
              label: 'معرض الصور',
              icon: 'pi pi-images',
              route: '/home/gallery'

            },]

        },

        {
          label: 'اخرى',
          icon: 'pi pi-star-fill',
          items: [
            {
              label: 'صندوق العائلة',
              icon: 'pi pi-star-fill',
              route: '/home/treasury'


            },

            {
              label: 'الصحة',
              icon: 'pi pi-heart-fill',
              route: '/home/health'

            },
            {
              label: 'روابط التعليم',
              icon: 'pi pi-graduation-cap',
              route: '/home/kids-learning'

            },
            {
              label: 'لمحات قانونية',
              icon: 'pi pi-warehouse',
              route: '/home/legal'

            },
            {
              label: 'حديث اليوم',
            }
          ]
        },
      ];
    }

    this.randomHadith()
  }

  randomHadith() {
    const today = new Date();
    let hadithNum = this.getDayOfYear(today)


    this.api.get("https://api.hadith.gading.dev/books/bukhari/" + hadithNum).subscribe((data: any) => {
      this.hadith = data.data

      console.log(this.hadith);
    })

  }
  getDayOfYear(date: any) {
    // Create a new date for January 1st of the current year
    const start: any = new Date(date.getFullYear(), 0, 1); // Month is 0-indexed (0 = January)

    // Calculate the difference in milliseconds between the given date and January 1st
    const diff = date - start;

    // Convert milliseconds to days (1 day = 1000ms * 60s * 60m * 24h)
    const dayOfYear = Math.floor(diff / (1000 * 60 * 60 * 24)) + 1; // Adding 1 as it's 1-based index

    return dayOfYear;
  }
  visible: boolean = false;
  hadith: any = null
  showDialog() {
    this.visible = true;

  }
}
