<p-menu #menu [model]="userMenu" [popup]="true" autoZIndex="true" baseZIndex="10000"
  styleClass="sticky top-0 left-0 z-10">
  <ng-template #nav pTemplate="item" let-item let-root="root">
    <a pRipple class="flex items-center p-menuitem-link  " [routerLink]="item.route">
      <span [class]="item.icon + ' text-[var(--icon-color)]'"></span>
      <span class="mr-2">{{ item.label }}</span>
      <span *ngIf="item.shortcut" class="mr-auto border-1 surface-border rounded surface-100 text-xs p-1">{{
        item.shortcut }}</span>
      <i *ngIf="item.items" [ngClass]="['pi', root ? 'pi-angle-down ml-2' : 'pi-angle-right ml-auto']"></i>
    </a>
  </ng-template>
  <ng-template pTemplate="end">
    <div class="relative overflow-hidden w-full flex items-center p-2 pr-3 text-color hover:surface-200"
      style="border-top: 2px solid #f1f1f0;">
      <p-avatar [image]="avatar ? avatar : 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'"
        class="ml-2" shape="circle" />
      <span class="flex flex-col mr-2 justify-start items-start">
        <span class="font-bold">{{ user?.fullname }}</span>
        <span class="text-sm">{{ role?.name == "admin" ? "مدير النظام" : role?.name == "editor" ?
          "محرر" : "مستخدم" }}</span>
      </span>
    </div>
  </ng-template>
</p-menu>

<p-avatar class="ml-2 cursor-pointer" (click)="menu.toggle($event)"
  [image]="avatar ? avatar : '../../../../assets/img/avatar-defult.png'" shape="circle" alt="User Avatar" />
<!-- <app-dark-mode></app-dark-mode> -->
