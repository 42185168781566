<footer
  class="w-full flex flex-col md:flex-row  justify-center  lg:justify-between items-center   p-5 rounded-t-md mt-20 ">

  <!-- right side  -->
  <div class="flex    justify-center  items-center">

    <div class="flex gap-2 justify-center items-center">
      <p>
        مدير النظام : م. صالح بازيد
      </p>
      <a class="no-underline" href="https://wa.me/0575523266?text=مرحبا" target="_blank">

        <i class="pi pi-whatsapp text-green-500  overflow-hidden  p-1  flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer"
          style="font-size: 16px;"></i>
      </a>

      <a class="no-underline" href="https://www.linkedin.com/in/saleh-bazaid-75211b170" target="_blank">

        <i class="pi pi-linkedin text-blue-500 overflow-hidden  p-1 flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer  "
          style="font-size: 16px;"></i>
      </a>
    </div>

  </div>


  <!-- left side  -->
  <div class="flex flex-col lg:flex-row  items-center">

    <!-- باحميد -->
    <div class="flex gap-2 justify-center items-center mx-2">
      <p>
        مطور النظام : م. عبدالعزيز باحميد
      </p>

      <a class="no-underline" href="https://wa.me/0552038602?text=مرحبا" target="_blank">
        <i class="pi pi-whatsapp text-green-500  overflow-hidden  p-1  flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer"
          style="font-size: 16px;">
        </i>
      </a>


      <a class="no-underline" href="https://sa.linkedin.com/in/abdulazizbahmeed?trk=public_profile_browsemap-profile"
        target="_blank">
        <i class="pi pi-linkedin text-blue-500 overflow-hidden  p-1 flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer  "
          style="font-size: 16px;">
        </i>
      </a>
    </div>


    <!-- احمد الجويحي -->
    <div class="flex gap-2 justify-center items-center mx-2">
      <p>
        مطور النظام : م. احمد الجويحي
      </p>

      <a class="no-underline" href="https://wa.me/+966534889865?text=مرحبا" target="_blank">

        <i class="pi pi-whatsapp text-green-500  overflow-hidden  p-1  flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer"
          style="font-size: 16px;">
        </i>
      </a>


      <a class="no-underline" href="https://sa.linkedin.com/in/ahmed-algowaihi-27bbba250" target="_blank">
        <i class="pi pi-linkedin text-blue-500 overflow-hidden  p-1 flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer  "
          style="font-size: 16px;"></i>
      </a>
    </div>



  </div>



</footer>