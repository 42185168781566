import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsMangementService } from '../../../modules/dashboard/component/news-mangement/service/news-mangement.service';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-edit-article-editor',
  templateUrl: './edit-article-editor.component.html',
  styleUrl: './edit-article-editor.component.css'
})
export class EditArticleEditorComponent {
  @Input() type = ''
  @Input() navigateTo = ''
  editNewsForm: any
  previewText: any


  selectedFile: File | null = null;
  fileContent: string | ArrayBuffer | null = null;

  constructor(
    private newsService: NewsMangementService,
    private toast: ToastService,
    private routes: ActivatedRoute,
    private route: Router,
    private fb: FormBuilder
  ) {

    this.editNewsForm = this.fb.group({
      id: [''],
      title: [''],
      cover_image: [''],
      content: ['']
    })
  }

  id = this.routes.snapshot.params['id'];
  ngOnInit(): void {
    this.newsService.getNewsById(this.id).subscribe((data) => {
      console.log(data);

      this.editNewsForm = this.fb.group({
        id: [data.data.id],
        title: [data.data.title],
        cover_image: [data.data.cover_image],
        content: [data.data.content],
        type: [this.type]
      });
      console.log(this.type);

    });
  }


  submit() {
    if (this.editNewsForm.invalid) {
      this.toast.showError('خطا', 'ادخل البيانات بشكل صحيح')
      return
    }
    const formData = new FormData();
    formData.append('title', this.editNewsForm.get('title').value);
    formData.append('cover_image', this.selectedFile ?? '');
    formData.append('content', this.editNewsForm.get('content').value);
    formData.append('type', this.type);

    console.log(formData.get('content'))

    this.newsService.updateNews(this.id, formData).subscribe({
      next: (res: any) => {
        this.editNewsForm.reset()
        this.route.navigate([this.navigateTo])
        this.toast.showSuccess('تم', res.message)
      }
    })
  }

  preview() {
    this.previewText = {
      title: this.editNewsForm.value.title,
      image_url: this.fileContent || this.editNewsForm.value.cover_image,
      text: this.editNewsForm.value.content
    }


  }

  //image
  onSelect(event: any) {
    this.selectedFile = event.files[0]; // Get the first selected file
    const reader = new FileReader();

    reader.onload = () => {
      this.fileContent = reader.result; // Save file content in a variable
    };

    if (this.selectedFile) {
      reader.readAsDataURL(this.selectedFile); // Read file content as Data URL
    }
  }

  onClear() {

    this.selectedFile = null;
    this.fileContent = null;
    console.log('File selection cleared');
  }
  onRemove(event: any) {
    this.onClear()
  }


}
