import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '../../../../../shared/services/toast/toast.service';
import { news } from '../../../../../../constent/routes';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsMangementService {

  constructor(
    private http: HttpClient,
    private toast: ToastService
  ) { }


  getAllNews(type: string): Observable<any> {
    return this.http.get(news.index + '?type=' + type)
  }

  getNewsById(id: any): Observable<any> {
    return this.http.get(news.index + id)
  }


  storeNews(data: any): Observable<any> {
    return this.http.post(news.create, data);
  }

  deleteNews(id: any): Observable<any> {
    return this.http.delete(news.delete + id);
  }

  updateNews(id: any, data: any): Observable<any> {
    return this.http.post(news.update + id + '?_method=PUT', data);
  }

  changeState(id: any, data: any): Observable<any> {
    return this.http.get(news.changeState + id + '/change-status?is_active=' + data);
  }

}
