import { AddArticlesService } from './add-articles.service';
import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NewsMangementService } from '../../../modules/dashboard/component/news-mangement/service/news-mangement.service';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-add-article-editor',
  templateUrl: './add-article-editor.component.html',
  styleUrl: './add-article-editor.component.css'
})
export class AddArticleEditorComponent {
  @Input() type = ''
  @Input() navigateTo = ''
  newsForm: any;
  previewText: any
  desc: any
  selectedFile: File | null = null;
  fileContent: string | ArrayBuffer | null = null;

  @ViewChild('tag_container') tag_container: any

  constructor(private fb: FormBuilder, private addService: AddArticlesService, private toast: ToastService, private routes: Router) {

    this.newsForm = this.fb.group({
      title: ['', Validators.required],
      cover_image: [''],
      content: ['', Validators.required],
    })
  }
  ngOnInit() {
    console.log(this.type);

  }


  submit() {

    if (this.newsForm.invalid) {
      this.toast.showError('خطا', 'ادخل البيانات بشكل صحيح')
      return
    }
    const formData = new FormData();
    formData.append('title', this.newsForm.get('title').value);
    formData.append('cover_image', this.selectedFile ?? '');
    formData.append('content', this.newsForm.get('content').value);
    formData.append('type', this.type);

    console.log(formData.get('content'))

    this.addService.storeArticle(formData).subscribe({
      next: (res: any) => {
        this.newsForm.reset()
        this.toast.showSuccess('تم', res.message)
        this.routes.navigate([this.navigateTo])
      }
    })
  }

  preview() {
    this.previewText = {
      title: this.newsForm.value.title,
      image_url: this.fileContent,
      text: this.newsForm.value.content
    }

    console.log(this.newsForm.value)

  }

  //image
  onSelect(event: any) {
    this.selectedFile = event.files[0]; // Get the first selected file
    const reader = new FileReader();

    reader.onload = () => {
      this.fileContent = reader.result; // Save file content in a variable
    };

    if (this.selectedFile) {
      reader.readAsDataURL(this.selectedFile); // Read file content as Data URL
    }
  }

  onClear() {

    this.selectedFile = null;
    this.fileContent = null;
    console.log('File selection cleared');
  }
  onRemove(event: any) {
    this.onClear()
  }



}



