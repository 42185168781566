import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  private isDarkTheme = new BehaviorSubject(localStorage.getItem('theme') === 'dark-theme' ? true : false);

  getTheme() {
    return this.isDarkTheme.value;

  }
  setTheme(theme: string) {
    this.switchTheme(theme);
    this.isDarkTheme.next(!this.isDarkTheme);
  }



  switchTheme(theme: string) {
    let themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
    let body = this.document.getElementsByTagName('body')[0];


    if (themeLink) {
      themeLink.href = theme + '.css'; // bundle name
      localStorage.setItem('theme', theme);
      body.style.backgroundColor = theme === 'light-theme' ? '#F3F3F3' : '';
    }

  }
}
