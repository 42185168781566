import { Component } from '@angular/core';
import { ThemeService } from '../../services/theme/theme.service';

@Component({
  selector: 'app-dark-mode',
  templateUrl: './dark-mode.component.html',
  styleUrl: './dark-mode.component.css'
})
export class DarkModeComponent {

  isDark: boolean
  constructor(private theme: ThemeService) {


    this.isDark = this.theme.getTheme()
  }


  switchTheme(theme: any) {
    let themeType = theme.checked ? 'dark-theme' : 'light-theme';
    this.theme.setTheme(themeType);

  }

}
