import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from '../../../constent/routes';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ToastService } from '../../shared/services/toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  avatarImg = new BehaviorSubject<string>(this.getUser()?.image_url || '');

  constructor(private http: HttpClient,
    private router: Router,
    private toast: ToastService
  ) { }

  login(data: any): Observable<any> {
    return this.http.post(auth.login, data)
  }

  signup(data: any) {
    return this.http.post(auth.register, data)
  }
  logout() {
    this.http.delete(auth.logout).subscribe((d) => {
      localStorage.clear();
      this.toast.showInfo('تم تسجيل الخروج بنجاح', 'تسعدنا زييارتك لنا مرة اخرى')
      this.router.navigate(['auth/login']);
    })
  }

  getToken() {
    return localStorage.getItem('token')
  }

  getRole() {
    return JSON.parse(localStorage.getItem('role') || '{}');
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }

  isLoggedIn() {
    return localStorage.getItem('token') ? true : false
  }







}
