import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ToastService } from './services/toast/toast.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MenubarModule } from 'primeng/menubar';
import { IconFieldModule } from 'primeng/iconfield';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
import { DarkModeComponent } from './components/dark-mode/dark-mode.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { FooterComponent } from './components/footer/footer.component';
import { InfoIconFlashComponent } from './components/info-icon-flash/info-icon-flash.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AddArticleEditorComponent } from './components/add-article-editor/add-article-editor.component';
import { EditArticleEditorComponent } from './components/edit-article-editor/edit-article-editor.component';
import { FileUploadModule } from 'primeng/fileupload';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { TreeNumbersComponent } from './components/tree-numbers/tree-numbers.component';
import { DialogModule } from 'primeng/dialog';



@NgModule({
  declarations: [
    NavbarComponent,
    UserMenuComponent,
    DarkModeComponent,
    LoaderComponent,
    FooterComponent,
    InfoIconFlashComponent,
    AddArticleEditorComponent,
    EditArticleEditorComponent,
    TreeNumbersComponent
  ],
  imports: [
    CommonModule,
    MenubarModule,
    IconFieldModule,
    SplitButtonModule,
    ButtonModule,
    ToggleButtonModule,
    MenuModule,
    BadgeModule,
    AvatarModule,
    FormsModule,
    OverlayPanelModule,
    FileUploadModule,
    ReactiveFormsModule,
    EditorModule,
    InputTextModule,
    DialogModule


  ],
  exports: [
    NavbarComponent,
    UserMenuComponent,
    LoaderComponent,
    FooterComponent,
    InfoIconFlashComponent,
    AddArticleEditorComponent,
    EditArticleEditorComponent,
    TreeNumbersComponent
  ],
  providers: [ToastService, MessageService]

})
export class SharedModule { }
