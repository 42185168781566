import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private message: MessageService
  ) { }

  showError(title: string, message: string, time: number = 3000) {
    this.message.add({ severity: 'error', summary: title, detail: message, icon: 'pi-times-circle ml-2 mt-2', life: time });
  }
  showWarning(title: string, message: string, time: number = 3000) {
    this.message.add({ severity: 'warn', summary: title, detail: message, life: time });
  }
  showSuccess(title: string, message: string, time: number = 3000) {
    this.message.add({
      severity: 'success', summary: title, detail: message, icon: 'pi-check-circle ml-2 mt-2', life: time
    });
  }
  showInfo(title: string, message: string, time: number = 3000) {
    this.message.add({
      severity: 'info', summary: title, detail: message, icon: 'pi-info-circle ml-2 mt-2', life: time
    });
  }
}
