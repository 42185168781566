<!-- user info  -->
<i class="pi pi-info-circle text-red-500 text-[20px]  mr-2  cursor-pointer info-icon" (click)="op.toggle($event)">
</i>

<p-overlayPanel #op>
  <div class="p-4 flex flex-col justify-center items-center">
    <h3>
      للاستفسارات والاقتراحات الرجاء التواصل معنا
    </h3>
    <!-- مدير النظام : م. صالح بازيد -->
    <p class="flex justify-center items-center">
      مدير النظام : م. صالح بازيد

      <a class="no-underline" href="https://wa.me/0575523266?text=مرحبا" target="_blank">

        <i class="pi pi-whatsapp text-green-500  overflow-hidden  p-1  flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer"
          style="font-size: 16px;"></i>
      </a>

      <a class="no-underline" href="https://www.linkedin.com/in/saleh-bazaid-75211b170" target="_blank">

        <i class="pi pi-linkedin text-blue-500 overflow-hidden  p-1 flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer  "
          style="font-size: 16px;"></i>
      </a>
    </p>


    <!-- مطور النظام : م. احمد الجويحي -->
    <p class="flex justify-center items-center">
      مطور النظام : م. احمد الجويحي

      <a class="no-underline" href="https://wa.me/+966534889865?text=مرحبا" target="_blank">

        <i class="pi pi-whatsapp text-green-500  overflow-hidden  p-1  flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer"
          style="font-size: 16px;"></i>
      </a>

      <a class="no-underline" href="https://sa.linkedin.com/in/ahmed-algowaihi-27bbba250" target="_blank">
        <i class="pi pi-linkedin text-blue-500 overflow-hidden  p-1 flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer  "
          style="font-size: 16px;"></i>
      </a>
    </p>

    <!-- مطور النظام : م. باحميد -->

    <p class="flex justify-center items-center">
      مطور النظام : م. عبدالعزيز باحميد

      <a class="no-underline" href="https://wa.me/0552038602?text=مرحبا" target="_blank">
        <i class="pi pi-whatsapp text-green-500  overflow-hidden  p-1  flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer"
          style="font-size: 16px;">
        </i>
      </a>


      <a class="no-underline" href="https://sa.linkedin.com/in/abdulazizbahmeed?trk=public_profile_browsemap-profile"
        target="_blank">
        <i class="pi pi-linkedin text-blue-500 overflow-hidden  p-1 flex justify-center items-center hover:scale-105 transition-all duration-300 cursor-pointer  "
          style="font-size: 16px;">
        </i>
      </a>
    </p>




  </div>
</p-overlayPanel>
